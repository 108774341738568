<script>
// import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Contacts-grid component
 */
export default {
  page: {
    title: "No Commission List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      loading: false,
      title: "No Commission List",
      items: [
        {
          text: "No Commission",
          href: "/admin",
        },
        {
          text: "No Commission List",
          active: true,
        },
      ],
      loadingfirst: true,
      fields: [
        {
          key: "id",
          value: "id",
        },
        {
          key: "name",
          value: "name",
        },
        {
          key: "email",
          value: "email",
        },
        {
          key: "currency_name",
          value: "currency_name",
        },
        {
          key: "Action",
          value: "Delete",
        },
      ],
      allData: [],
    };
  },
  mounted() {
    this.TypeDetails();
  },
  methods: {
    async TypeDetails() {
      var result = await ApiClass.getRequest("admin/no_commission/get", true);

      if (result.data.status_code == 1) {
        this.loadingfirst = false;
        this.allData = result.data.data;
        // console.log(this.allData);
      }
    },

    viewtemp(item) {
      this.viewtempdata = item;
    },
    async deleteApi(id = null) {
      this.$swal({
        title: "Please Confirm..",
        text: "Are you sure you want to delete No-commission?",
        icon: "warning",
        iconColor: "#CF0404",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#CF0404",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.final(id);
        }
      });
    },
    async final(id) {
      var result = await ApiClass.updateRequest(
        "admin/no_commission/remove?user_id=" + id,
        true
      );

      if (result.data.status_code == 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });

        this.TypeDetails();
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <filter section> -->

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">No Commission List</h4>

            <!-- No Result Found -->
            <div v-if="!loadingfirst">
              <b-table
                striped
                hover
                :items="allData"
                :fields="fields"
                :busy="loadingfirst"
                ref="table"
                show-empty
                sortable="false"
              >
                <template #empty>
                  <div class="d-flex justify-content-center mb-3">
                    No Record Found
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <!-- <strong>Loading...</strong> -->
                  </div>
                </template>

                <!-- A virtual column -->
                <template #cell(id)="data">
                  <span> {{ data.item.id }}</span>
                </template>

                <!-- A virtual column -->
                <template #cell(name)="data">
                  <span> {{ data.item.name }}</span>
                </template>

                <!-- A virtual column -->
                <template #cell(email)="data">
                  <span> {{ data.item.email }}</span>
                </template>

                <!-- A virtual column -->
                <template #cell(currency_name)="data">
                  <span> {{ data.item.currency_name }}</span>
                </template>
                <template #cell(action)="data">
                  <span @click="deleteApi(data.item.id)"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                    >
                      <path
                        d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
                      ></path>
                      <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path></svg
                  ></span>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
